import React from "react";
import { graphql } from "gatsby";

import Layout from "../components/Layout";

export default function Template({ data }) {
  const { markdownRemark } = data;
  const { frontmatter, html } = markdownRemark;

  return (
    <Layout currentPath={frontmatter.slug} title={frontmatter.title}>
      <div>
        <section className="bg-gray-100 px-4 py-6 dark:bg-gray-900">
          <div className="text-center text-primary-700 text-sm dark:text-primary-300">
            {frontmatter.date}
          </div>
          <h1 className="text-2xl md:text-3xl text-center font-bold dark:text-primary-100">
            {frontmatter.title}
          </h1>

          <div className="mt-4 flex justify-center items-center">
            <div>
              <div className="bg-gray-700 rounded-full w-10 h-10"></div>
            </div>
            <div className="px-2 m-0">
              <p className="m-0 text-sm dark:text-white">
                {frontmatter.author}
              </p>
            </div>
          </div>
        </section>
        <div
          className="p-4 container mx-auto dark:text-gray-300 article"
          dangerouslySetInnerHTML={{ __html: html }}
        />
      </div>
    </Layout>
  );
}

export const pageQuery = graphql`
  query($slug: String!) {
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        slug
        title
        author
        handler_label
        handler_link
      }
    }
  }
`;
